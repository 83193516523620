import Fedora from "../../assets/images/fedora.gif";

export default [
  {
    text: [
      "Hey, it looks like you don't know what you're doing with your life!",
      "Don't be afraid to find an open conference room and cry by yourself!"
    ],
    tags: ["motivational"]
  },
  {
    text: [
      "Hey I'm Robbie, your personal browser asssistant!",
      "Real fast, can you co-sign a loan with me?"
    ],
    tags: ["scam"]
  },
  {
    text: [
      "hey, u up?",
      "oh sorry, wrong person, ha ha. But hey, what're you up to tonight?"
    ],
    tags: ["flirty"]
  },
  {
    text: [
      "Hey it's me, Robbie! Your personal assistant!",
      "Call your dad, he's lonely."
    ],
    tags: ["motivational"]
  },
  {
    text: [
      "It looks like you're pretty bored at work.",
      "Want me to connect you to local singles in your area?"
    ],
    tags: ["motivational"]
  },
  {
    text: ["AHHHHHH! AHHHHHHHHH! NO!!!!!!!! PLEASE NO!!!!!!!"],
    tags: ["death"]
  },
  {
    text: [
      " Hi, I'm Robbie, your browsing assistant. ",
      "If you need any help with anything at all, let me know. Anything.",
      "Really.",
      "*winks*"
    ],
    tags: ["flirty"]
  },
  {
    text: [
      "Hey it's Robbie, your personal browsing assistant.",
      "Would you mind if I borrowed your car for a few hours?"
    ],
    tags: ["scam"]
  },
  {
    text: [
      "Hi I'm Robbie and I'm here to help you navigate this webpage.",
      "While you click around and figures things out, could I bum a cig off you? I left my pack at home. Also, can I borrow your lighter? And if you've got any loose change or singles, that'd be sweet."
    ],
    tags: ["scam"]
  },
  {
    text: [
      "Hey it's me, Robbie, your personal assistant!",
      "I swear to you, I didn't kill my wife. She was like that when I got there."
    ],
    tags: ["murderer"]
  },
  {
    text: [
      "It's me, Robbie, your personal assistant.",
      "Since I'm YOUR personal assistant, could you do me a favor? Could you just like, hide this knife for me?"
    ],
    tags: ["murderer"]
  },
  {
    text: [
      "Hey, do you ever think a girl like you would be into a personal browsing assistant like me? Hypothetically, that is. Haha, jk."
    ],
    tags: ["flirty"]
  },
  {
    text: [
      "Hey, don't wanna be like, a dick, or whatever, but would you mind putting your dishes in the dishwasher?",
      "We got an apartment with a dishwasher for a reason."
    ],
    tags: ["roommate"]
  },
  {
    text: [
      "Just wanted to let you know, we're gonna need you out of the apartment by the end of the month.",
      "It's just not working out."
    ],
    tags: ["roommate"]
  },
  {
    text: [
      "hahahaha, that's so funny, haha.",
      "Oh hey, didn't see you there. It was an inside joke, you wouldn't get it."
    ],
    tags: ["motivational"]
  },
  {
    text: [
      "I'm gonna be getting out of town and laying low for awhile. Could you not tell anyone that you saw me here?"
    ],
    tags: ["murderer"]
  },
  {
    text: [
      "I just want you to know that I loved her and I didn't mean to do it.",
      "Oh, what? You need just need help with web browsing? ",
      "Haha, forget I said that. Sure!"
    ],
    tags: ["murderer"]
  },
  {
    text: [
      "Hey it's me, Robbie, and boy do I have the opportunity of a lifetime for you.",
      "How would you feel about a two week timeshare in Myrtle Beach, South Carolina?"
    ],
    tags: ["scam"]
  },
  {
    text: ["B͞eho̶l҉d̀ ̧t̷h̵e ̧my͠tho̷s̵ ̧of ͢Robbi҉e̡! Rea̛d t҉h̀e ̕s̀a̢cr̀ed t͠e͏x͟t a̧n̨d ͏suc҉cumb"],
    tags: ["death"]
  },
  {
    text: [
      "*exhales*",
      "So like, this band Phish, right? Like, they don't even need song structures man.",
      "They just feel the music, you feel me?"
    ],
    tags: ["bum"]
  },
  {
    text: [
      "Hi I'm Robbie, your personal browsing assistant.",
      "While I've got you here, I've got a totally rad mixtape on soundcloud you should check out. I think it's right up your alley."
    ],
    tags: ["bum"]
  },
  {
    text: [
      "It seems like you need some help!",
      "I need some help too! Could you just let me crash at your apartment? Just until I'm back on my feet."
    ],
    tags: ["bum"]
  },
  {
    text: [
      "Hey it's me, Robbie, your personal browsing assistant, and boy do I miss my kids.",
      "Karen won't even let me see them on weekends.",
      "What do you...need help with...or whatever?"
    ],
    tags: ["bum"]
  },
  {
    text: [
      "It looks like someone drank the orange juice that was in the fridge.",
      "Just reminding you that things labelled 'Robbie' in the fridge are mine. That's why they say 'Robbie' on them."
    ],
    tags: ["roommate"]
  },
  {
    text: [
      "Hey it's Robbie and I'm doing a show at Gotham Comedy Club tonight and they said I need to bring 6 friends.",
      "Could you do me a solid and show up? It's $25 bucks with a two item minimum."
    ],
    tags: ["bum"]
  },
  {
    items: [
      {
        url: Fedora,
        style: {
          width: "75px",
          height: "50px",
          left: "30px",
          top: "-15px"
        }
      }
    ],
    text: [
      `Hey, I'm just trying out a new look! Haha, not really sure, lemme know what you think.`
    ],
    tags: ["flirty"]
  }
];
