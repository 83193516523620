export const INITIAL_STATE = {
  width: 100,
  height: 100,
  resizeStartX: 0,
  resizeStartY: 0,
  currX: 0,
  currY: 0,
  prevX: 0,
  prevY: 0,
  startX: 0,
  startY: 0,
  dragging: false,
  resizing: false
};

const createPositions = () => {
  const postions = [];

  for (let i = 0; i < 100; i += 100) {
    const position = {};
    for (let i = 0; i < 100; i += 100) {}
  }
};

export const POSITIONS = {};
